export default class ScrollDirection {
	constructor() {
		this.currentScrollTop = 0;
		this.prevScrollTop = 0;
		this.currentDir = false;

		// Binding
		this.init = this.init.bind(this);
		this.destroy = this.destroy.bind(this);
	}

	/**
	 * Set down or up data on body
	 */
	onScroll() {
		this.currentScrollTop =
			window.pageYOffset || document.documentElement.scrollTop;
		const scrolled = this.currentScrollTop > this.prevScrollTop;
		if (!this.currentDir && scrolled) {
			// On down scroll
			this.prevScrollTop = this.currentScrollTop;
			document.body.setAttribute("data-scroll", "down");
			this.currentDir = true;
		} else if (
			this.currentDir &&
			this.currentScrollTop < this.prevScrollTop
		) {
			// On up scroll
			this.prevScrollTop = this.currentScrollTop;
			document.body.setAttribute("data-scroll", "up");
			this.currentDir = false;
		}
		this.prevScrollTop = this.currentScrollTop;
	}

	/**
	 * Set initial value and add event listener on window scroll
	 */
	init() {
		// Set initial data-scroll
		document.body.setAttribute("data-scroll", "up");

		// Add scroll listener
		window.addEventListener("scroll", this.onScroll, false);
	}

	/**
	 * Remove event listener on window scroll
	 */
	destroy() {
		// Remove data-scroll
		document.body.removeAttribute("data-scroll");

		// Remove scroll listener
		window.removeEventListener("scroll", this.onScroll, false);
	}
}
