import Swiper from "swiper";

export default class Swipino {
	constructor(el, props) {
		// Check element
		if (el instanceof HTMLElement) {
			this.el = el;
		} else {
			this.el = document.querySelector(el);
		}
		if (!this.el) {
			console.warn("No valid swipino found. Sorry bro.");
			return false;
		}

		// Props
		this.options = props.options;
		this.class = this.el.className;
		this.initialized = false;

		// Get class names
		this.wrapperClass =
			void 0 !== props.wrapperClass
				? props.wrapperClass
				: `${this.class}__rows`;
		this.slideClass =
			void 0 !== props.slideClass
				? props.slideClass
				: `${this.class}__row`;
		this.breakpoint =
			void 0 !== props.breakpoint ? props.breakpoint : false;

		// Only init if we're under breakpoint width
		if (!this.breakpoint || window.innerWidth < this.breakpoint) {
			this.init();
		}

		// Listen resize
		if (this.breakpoint) {
			window.addEventListener("resize", this.resize.bind(this));
		}
	}

	init() {
		// Add classes to DOM
		this.addClasses();

		// Pagination
		if (
			void 0 !== this.options.pagination &&
			void 0 !== this.options.pagination.el
		) {
			this.createPagination(this.options.pagination.el);
		}

		this.swiper = new Swiper(this.el, this.options);
		this.initialized = true;
	}

	addClasses() {
		// Container
		this.el.classList.add("swiper-container");

		// Wrapper
		this.wrapper = this.el.querySelector(`.${this.wrapperClass}`);
		if (!this.wrapper) {
			// If there's no wrapper, wrap slides
			this.wrapper = document.createElement("div");
			this.wrapper.classList.add("swiper-wrapper");
			this.wrapper.innerHTML = this.el.innerHTML;
			this.el.innerHTML = "";
			this.el.append(this.wrapper);
		} else {
			this.wrapper.classList.add("swiper-wrapper");
		}

		// Slides
		this.slides = this.el.querySelectorAll(`.${this.slideClass}`);
		this.slides.forEach(slide => {
			slide.classList.add("swiper-slide");
		});
	}

	createPagination(paginationClass) {
		// Create pagination div
		this.pagination = document.createElement("div");
		this.pagination.classList.add("swiper-pagination");
		this.el.append(this.pagination);
	}

	destroy() {
		// Destroy swiper
		this.swiper.destroy();

		// Remove classes
		this.el.classList.remove("swiper-container");
		this.slides.forEach(slide => {
			slide.classList.remove("swiper-slide");
		});

		// Remove wrapper div
		this.el.innerHTML = this.wrapper.innerHTML;
		this.wrapper.remove();

		this.initialized = false;
	}

	resize() {
		if (!this.initialized && window.innerWidth < this.breakpoint) {
			this.init();
		} else if (this.initialized && window.innerWidth >= this.breakpoint) {
			this.destroy();
		}
	}
}
