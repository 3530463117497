import Swipino from "../libs/Swipino";

export default class HomeRenderer {
	onEnter() {
		// Gallery swiper
		if (document.querySelector(".m-gallery")) {
			new Swipino(".m-gallery", {
				slideClass: "m-gallery_item",
				breakpoint: 1024,
				options: {
					speed: 400,
					slidesPerView: "auto",
					spaceBetween: 16,
					freeMode: true,
				},
			});
		}

		// Cards swiper
		if (document.querySelector(".m-cards")) {
			new Swipino(".m-cards", {
				slideClass: "m-cards_card",
				breakpoint: 1024,
				options: {
					speed: 400,
					slidesPerView: "auto",
					spaceBetween: 16,
					freeMode: true,
				},
			});
		}

		// Logos swiper
		if (document.querySelector(".m-logos")) {
			new Swipino(".m-logos", {
				slideClass: "m-logos_logo",
				breakpoint: 1024,
				options: {
					speed: 400,
					slidesPerView: "auto",
					spaceBetween: 16,
					freeMode: true,
				},
			});
		}
	}
}
