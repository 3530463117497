import "@/scss/main.scss";
import "@/js/gen-svg-spritesheet";
import "lazysizes";
import "lazysizes/plugins/respimg/ls.respimg";

import Notify from "@/js/libs/Notify";
import ScrollDirection from "@/js/libs/ScrollDirection";
import { bootstrapBreakpoints } from "@/js/modules/breakpoints";
import { makeBrowserDetector } from "@/js/modules/browserDetector";
import HomeRenderer from "@/js/renderers/HomeRenderer";
import PageRenderer from "@/js/renderers/PageRenderer";
// import store from '@/js/store'

function modalVideo() {
	//Agrandir vidéo ou ouvrir le chat
	const fullscreen = document.querySelector(".a-video_fullscreen");
	const tchat = document.querySelector(".a-video_tchat");
	const blocVideo = document.querySelector(".a-video");
	const close = document.querySelector(".a-video_close");
	// const containerVideo = document.querySelector(".a-video_container");

	if (fullscreen && tchat) {
		fullscreen.addEventListener("click", function () {
			blocVideo.classList.add("-active");
			// containerVideo.innerHTML =
			// 	'<iframe width="640" height="320" src=https://player.streamfizz.live/embed/live/ckvmzl41f1609903kvif0wcprxq frameborder="0" allow="accelerometer; autoplay; encrypted-media; picture-in-picture" allowFullScreen></iframe>';
		});
		tchat.addEventListener("click", function () {
			blocVideo.classList.add("-active");
			// containerVideo.innerHTML =
			// 	'<iframe width="640" height="320" src=https://player.streamfizz.live/embed/live/ckvmzl41f1609903kvif0wcprxq frameborder="0" allow="accelerometer; autoplay; encrypted-media; picture-in-picture" allowFullScreen></iframe>';
		});
	}

	if (close) {
		close.addEventListener("click", function () {
			blocVideo.classList.remove("-active");
			// containerVideo.innerHTML =
			// 	'<iframe width="640" height="320" src=https://player.streamfizz.live/embed/live/ckvmzl41f1609903kvif0wcprxq?hidesidebar frameborder="0" allow="accelerometer; autoplay; encrypted-media; picture-in-picture" allowFullScreen></iframe>';
		});
	}
}

function countdown() {
	const second = 1000;
	const minute = second * 60;
	const hour = minute * 60;
	const day = hour * 24;

	//I'm adding this section so I don't have to keep updating this pen every year :-)
	//remove this if you don't need it
	let today = new Date();
	const dd = String(today.getDate()).padStart(2, "0");
	const mm = String(today.getMonth() + 1).padStart(2, "0");
	const yyyy = today.getFullYear();
	const monthDay = "11/30/";
	let event = monthDay + yyyy;

	today = `${mm}/${dd}/${yyyy}`;
	if (today > event) {
		event = monthDay + yyyy;
	}
	//end

	const countDown = new Date(`${event} 15:00:00`).getTime();

	const x = setInterval(function () {
		const now = new Date().getTime();
		const distance = countDown - now;
		const days = document.getElementById("m-countdown_days");
		const secondes = document.getElementById("m-countdown_seconds");
		const minutes = document.getElementById("m-countdown_minutes");
		const hours = document.getElementById("m-countdown_hours");

		const sc = Math.floor((distance % minute) / second);
		const mn = Math.floor((distance % hour) / minute);
		const hr = Math.floor((distance % day) / hour);
		const jr = Math.floor(distance / day);

		days.querySelector(".m-countdown_cercle").style.transform = `rotateZ(${
			jr * 18
		}deg)`;
		hours.querySelector(".m-countdown_cercle").style.transform = `rotateZ(${
			(hr + mn / 12) * 6
		}deg)`;
		minutes.querySelector(
			".m-countdown_cercle"
		).style.transform = `rotateZ(${mn * 6}deg)`;
		secondes.querySelector(
			".m-countdown_cercle"
		).style.transform = `rotateZ(${sc * 6}deg)`;

		days.querySelector(".m-countdown_result").innerText = jr;
		hours.querySelector(".m-countdown_result").innerText = hr;
		minutes.querySelector(".m-countdown_result").innerText = mn;
		secondes.querySelector(".m-countdown_result").innerText = sc;

		//do something later when date is reached
		if (distance < 0) {
			document.getElementById("countdown").style.display = "none";
			document.getElementById("iframe").style.display = "block";
			clearInterval(x);
		}
		//seconds
	}, 0);
}
function animateReveal() {
	const threshold = 0.1;
	const options = {
		root: null,
		rootMargin: "0px",
		threshold,
	};

	const handleIntersect = function (entries, observer) {
		entries.forEach(function (entry) {
			if (entry.intersectionRatio > threshold) {
				entry.target.classList.remove("a-reveals_reveal");
				observer.unobserve(entry.target);
			}
		});
	};

	document.documentElement.classList.add("a-reveals");

	const observer = new IntersectionObserver(handleIntersect, options);
	const targets = document.querySelectorAll(".a-reveals_reveal");
	if (targets) {
		targets.forEach(function (target) {
			observer.observe(target);
		});
	}
}

document.addEventListener("DOMContentLoaded", function () {
	// Renderer
	let renderer = false;
	switch (document.querySelector(".o-body").dataset.type) {
		case "home":
			renderer = new HomeRenderer();
			break;
		default:
			renderer = new PageRenderer();
			break;
	}
	renderer.onEnter();

	// Breakpoints
	bootstrapBreakpoints();
	modalVideo();
	countdown();
	animateReveal();

	// Scroll direction
	const scrollDirection = new ScrollDirection();
	scrollDirection.init();

	// ContentInfo
	const notify = new Notify();
	const contentinfos = document.querySelectorAll('div[role="contentinfo"]');
	contentinfos.forEach(contentinfo => {
		notify.add(
			contentinfo,
			!contentinfo.classList.contains("contentinfo--error")
				? "good"
				: "bad"
		);
	});
});

// register CSS classes on <html> to help with browser detection in CSS
makeBrowserDetector(window).bootstrapClasses();

// SWPL LOG
console.log(
	"%c swimmingpool ",
	`background: #1467ff;
	color: white;
	font-size: 17px;
	font-weight: bold;
	line-height: 36px; text-align: center`,
	" www.swpl.fr"
);
